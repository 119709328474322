import { json, useLoaderData } from '@remix-run/react';
import { LoaderFunctionArgs } from '@remix-run/node';

export async function loader({ request, params }: LoaderFunctionArgs) {

  return {
    ENV: {
      BUILD_NUMBER: process.env.BUILD_NUMBER
    }
  }
}

export default function Index() {
  const { ENV } = useLoaderData<typeof loader>();

  return (
    <div>
      Build: {ENV.BUILD_NUMBER}
    </div>
  );
}
